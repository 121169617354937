.choices {
  margin-bottom: 24px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: 0;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  background-color: #eaeaea;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 0;
  padding: 10px;
  display: block;
}

.choices[data-type*="select-one"] .choices__button {
  opacity: .25;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-radius: 10em;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-right: 25px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
}

.choices[data-type*="select-one"] .choices__button:focus, .choices[data-type*="select-one"] .choices__button:hover {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}

.choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
  display: none;
}

.choices[data-type*="select-one"]:after {
  content: "";
  pointer-events: none;
  border: 5px solid #0000;
  border-top-color: #333;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  position: absolute;
  top: 50%;
  right: 11.5px;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: #0000 #0000 #333;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  margin-left: 25px;
  margin-right: 0;
  left: 0;
  right: auto;
}

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  opacity: .75;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-left: 1px solid #008fa1;
  border-radius: 0;
  width: 8px;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover {
  opacity: 1;
}

.choices__inner {
  vertical-align: top;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  width: 100%;
  min-height: 44px;
  padding: 7.5px 7.5px 3.75px;
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  width: 100%;
  padding: 4px 16px 4px 4px;
  display: inline-block;
}

[dir="rtl"] .choices__list--single {
  padding-left: 16px;
  padding-right: 4px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  vertical-align: middle;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  border-radius: 20px;
  margin-bottom: 3.75px;
  margin-right: 3.75px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-left: 3.75px;
  margin-right: 0;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  word-break: break-all;
  will-change: visibility;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  width: 100%;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -1px;
  top: auto;
  bottom: 100%;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 300px;
  position: relative;
  overflow: auto;
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  padding: 10px;
  font-size: 14px;
  position: relative;
}

[dir="rtl"] .choices__list--dropdown .choices__item, [dir="rtl"] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}

@media (width >= 640px) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable:after, .choices__list[aria-expanded] .choices__item--selectable:after {
    content: attr(data-select-text);
    opacity: 0;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable:after {
    left: 10px;
    right: auto;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  color: gray;
  border-bottom: 1px solid #f7f7f7;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
}

.choices__button:focus, .choices__input:focus {
  outline: 0;
}

.choices__input {
  vertical-align: baseline;
  background-color: #f9f9f9;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 4px 0 4px 2px;
  font-size: 14px;
  display: inline-block;
}

.choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__input::-webkit-search-decoration {
  display: none;
}

.choices__input::-webkit-search-results-button {
  display: none;
}

.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.choices__input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

[dir="rtl"] .choices__input {
  padding-left: 0;
  padding-right: 2px;
}

.choices__placeholder {
  opacity: .5;
}
/*# sourceMappingURL=styles.98901387.css.map */
